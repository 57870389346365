'use client';

import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/navigation';
import { Button } from 'atoms/Button';
import { COOKIE_CONSENT, GA_CONSENT } from 'constants/storage';
import { Toggle } from 'atoms/Toggle';
export const CookieBanner: React.FC = () => {
  const t = useTranslations();
  const route = useRouter();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isGAActive, setGAActive] = useState<boolean>(false);
  useEffect(() => {
    const cookieConsent = localStorage.getItem(COOKIE_CONSENT);
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);
  const handleAccept = () => {
    localStorage.setItem(COOKIE_CONSENT, 'true');
    setIsVisible(false);
    route.refresh();
  };
  const handleAcceptAll = () => {
    Cookies.set(GA_CONSENT, 'true', {
      expires: 365
    });
    handleAccept();
  };
  const handleAcceptSelected = () => {
    Cookies.set(GA_CONSENT, isGAActive.toString(), {
      expires: 365
    });
    handleAccept();
  };
  if (!isVisible) return null;
  return <div className="fixed bottom-6 right-6 z-40 flex w-[600px] items-center justify-center px-10 pb-8 pt-10 max-lg:bottom-0 max-lg:right-0 max-lg:w-screen" data-sentry-component="CookieBanner" data-sentry-source-file="CookieBanner.tsx">
			<div className="absolute h-full w-full bg-gray-950 opacity-80" />

			<div className="z-50 flex flex-col items-center justify-center gap-6">
				<div className="z-50 flex flex-col gap-2">
					<p className="text-[13px] font-semibold text-white">
						{t('cookie-consent-essential-heading')}
					</p>

					<div className="z-50 flex items-center justify-center gap-4">
						<p className="text-[13px] text-white">{t('cookie-consent-essential')}</p>

						<Toggle isActive disabled data-sentry-element="Toggle" data-sentry-source-file="CookieBanner.tsx" />
					</div>
				</div>

				<div className="z-50 flex flex-col gap-2">
					<p className="text-[13px] font-semibold text-white">
						{t('cookie-consent-analytical-heading')}
					</p>

					<div className="z-50 flex items-center justify-center gap-4">
						<p className="text-[13px] text-white">{t('cookie-consent-analytical')}</p>

						<Toggle isActive={isGAActive} setActive={setGAActive} data-sentry-element="Toggle" data-sentry-source-file="CookieBanner.tsx" />
					</div>
				</div>

				<div className="flex flex-wrap justify-center gap-5">
					<Button onClick={handleAcceptAll} isActive isSmall reverseColors data-sentry-element="Button" data-sentry-source-file="CookieBanner.tsx">
						{t('accept-button-all')}
					</Button>

					<Button onClick={handleAcceptSelected} isSmall reverseColors data-sentry-element="Button" data-sentry-source-file="CookieBanner.tsx">
						{t('accept-button-selected')}
					</Button>
				</div>
			</div>
		</div>;
};