'use client';

import { useLocale } from 'next-intl';
import { memo } from 'react';
import { LANGUAGES, type RoutesWithoutSlug, usePathname, useRouter } from '@/i18n/routing';
export const LocaleToggle = memo(() => {
  const rawPathname = usePathname();
  const router = useRouter();
  const locale = useLocale();
  const pathname = typeof rawPathname === 'string' ? rawPathname : '/';
  const currentLanguage = locale === LANGUAGES.PL ? LANGUAGES.EN : LANGUAGES.PL;
  const handleToggleLocale = () => router.replace(pathname as RoutesWithoutSlug, {
    locale: currentLanguage
  });
  return <p className="cursor-pointer p-1 font-black" onClick={handleToggleLocale}>
			{currentLanguage.toUpperCase()}
		</p>;
});
LocaleToggle.displayName = 'LocaleToggle';