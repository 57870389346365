import { defineRouting } from 'next-intl/routing';
import { createNavigation } from 'next-intl/navigation';
import { LANGUAGE } from 'constants/storage';

export const LANGUAGES = {
	PL: 'pl',
	EN: 'en',
} as const;

export type RoutesWithoutSlug = '/' | '/offer' | '/projects' | '/about-us' | '/contact';

export type IntlRoute =
	| RoutesWithoutSlug
	| { pathname: '/projects/[slug]'; params: { slug: string } };

export type LanguagesType = (typeof LANGUAGES)[keyof typeof LANGUAGES];

export const locales = [LANGUAGES.EN, LANGUAGES.PL] as const;

export const routing = defineRouting({
	locales,
	defaultLocale: LANGUAGES.PL,
	localePrefix: 'always',
	localeCookie: {
		secure: true,
		name: LANGUAGE,
	},
	pathnames: {
		'/': '/',
		'/offer': {
			[LANGUAGES.PL]: '/oferta',
			[LANGUAGES.EN]: '/offer',
		},
		'/projects': {
			[LANGUAGES.PL]: '/realizacje',
			[LANGUAGES.EN]: '/projects',
		},
		'/projects/[slug]': {
			[LANGUAGES.PL]: '/realizacje/[slug]',
			[LANGUAGES.EN]: '/projects/[slug]',
		},
		'/about-us': {
			[LANGUAGES.PL]: '/o-nas',
			[LANGUAGES.EN]: '/about-us',
		},
		'/contact': {
			[LANGUAGES.PL]: '/kontakt',
			[LANGUAGES.EN]: '/contact',
		},
	},
});

export const { Link, redirect, usePathname, useRouter, getPathname } = createNavigation(routing);
