import clsx from 'clsx';
type ToggleProps = {
  isActive: boolean;
  setActive?: (isOn: boolean) => void;
  disabled?: boolean;
};
export const Toggle = ({
  isActive,
  setActive,
  disabled
}: ToggleProps) => {
  const handelToggle = () => {
    setActive?.(!isActive);
  };
  return <div className="flex items-center space-x-2" data-sentry-component="Toggle" data-sentry-source-file="Toggle.tsx">
			<button onClick={handelToggle} className={clsx('relative inline-flex h-6 w-12 items-center rounded-full bg-gray-950 transition-colors duration-300', {
      ['bg-green-600']: isActive,
      ['bg-green-600 opacity-30']: disabled
    })} disabled={disabled}>
				<span className={clsx('absolute block h-6 w-6 rounded-full bg-gray-100 transition-all duration-300', {
        ['translate-x-6']: isActive
      })} />
			</button>
		</div>;
};
Toggle.displayName = 'Toggle';